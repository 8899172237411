import React from 'react'

function Background() {
  return (
    <div>
        <img src='images/three-cheers-logo.png'
             alt='three-cheers-for-ramirez-logo'
             className='bg-logo'>
        </img>
    </div>
  )
}

export default Background