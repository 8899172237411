import React from 'react'

function Header() {
    function showGigsModal() {
       document.getElementById('gigs-modal-content').style.visibility = 'visible';
       document.getElementById('media-modal-content').style.visibility = 'hidden';
       document.getElementById('merch-modal-content').style.visibility = 'hidden';
    
       document.getElementById('modal').style.height = '100%';
       document.getElementById('close-button').style.visibility = 'visible';
    }
    function showMediaModal() {
        document.getElementById('media-modal-content').style.visibility = 'visible';
        document.getElementById('gigs-modal-content').style.visibility = 'hidden';
        document.getElementById('merch-modal-content').style.visibility = 'hidden';

        document.getElementById('modal').style.height = '100%';
        document.getElementById('close-button').style.visibility = 'visible';
     }
     function showMerchModal() {
        document.getElementById('merch-modal-content').style.visibility = 'visible';
        document.getElementById('gigs-modal-content').style.visibility = 'hidden';
        document.getElementById('media-modal-content').style.visibility = 'hidden';
        
        document.getElementById('modal').style.height = '100%';
        document.getElementById('close-button').style.visibility = 'visible';
     }
    
    return (
    <div className='header'>
            <div className='nav-bar'>
                <div className='nav-link'>
                    <img 
                        src='images/gigs.png'
                        alt='gigs'
                        className='nav-link-img'
                        onClick={showGigsModal}
                        >
                    </img>
                        <div className='nav-link-shadow'></div>
                </div>

                <div className='nav-link'>
                    <img 
                        src='images/media.png'
                        alt='media'
                        className='nav-link-img'
                        onClick={showMediaModal}
                        
                    ></img>
                        <div className='nav-link-shadow'></div>
                </div>

                <div className='nav-link'>
                    <img 
                        src='images/merch.png'
                        alt='merch'
                        className='nav-link-img'
                        onClick={showMerchModal}
                    ></img>
                        <div className='nav-link-shadow'></div>
                </div>   
            </div>
    </div>
  )
}

export default Header