import React from 'react'

function Modal() {
  function closeModal() {
    document.getElementById('modal').style.height = '0px';
    document.getElementById('gigs-modal-content').style.visibility = 'hidden';
    document.getElementById('media-modal-content').style.visibility = 'hidden';
    document.getElementById('merch-modal-content').style.visibility = 'hidden';
    document.getElementById('close-button').style.visibility = 'hidden';
  }

  function openVideoLink() {
    window.open('https://www.facebook.com/100063626288441/videos/pcb.535192401944965/1250388895528994');
  }

  function openVideoLink2() {
    window.open('https://www.facebook.com/100063626288441/videos/pcb.535192401944965/648675750247320');
  }

return (
    <div id='modal'>
      <div id='close-button'
            onClick={closeModal}>x</div>
      <div id='gigs-modal-content'>
        <h2 className='modal-title'>Gigs</h2>
          <p className='modal-blurb'>
            We don't have any gigs booked currently.
            <br></br>
            Stay tuned for more news on upcoming shows.
            <br></br><br></br>
            If you would like to book us, please contact us
            via email by clicking <a href="mailto:3cheers4ramirez@proton.me">HERE</a>
          </p>
      </div>
      <div id='media-modal-content'>
        <h2 className='modal-title'>Media</h2>
          <p className='modal-blurb'>
            Jam Night @ The Plough Inn Oswestry...
            <br></br><br></br>
            Click 
            <button className='vid-link' 
                    onClick={openVideoLink}>HERE</button> 
             to see a short clip of us playing our song "Animal" 
            at The Plough in Oswestry, Shropshire.
            <br></br><br></br>
            Click 
            <button className='vid-link'
                    onClick={openVideoLink2}>HERE</button> 
             to see a short clip of us playing our song 
             "The Spanish Inquisition" 
            at The Plough in Oswestry, Shropshire.
          </p>
      </div>
      <div id='merch-modal-content'>
      <h2 className='modal-title'>Merch</h2>
          <p className='modal-blurb'>
            Merch coming soon...
          </p>
      </div>
    </div>
  )
}

export default Modal