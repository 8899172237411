import './App.css';
import Header from './Components/Header';
import Modal from './Components/Modal';
import Background from './Components/Background';

function App() {
  return (
    <div className="App">
      <Header />
      <Background />
      <Modal />
    </div>
  );
}

export default App;
